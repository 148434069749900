<template lang="pug">
.comments__item
  .comments__item-top
    img.comments__item-avatar(
      :src="computedAvatar",
      :alt="$options.text.avatar",
      loading="lazy"
    )
    .comments__item-top-wrapper
      span.comments__item-author {{ author }}
      time.comments__item-date(:datetime="timestamp") {{ commentTimeAgo }}
    p.comments__item-rating
      span.comments__item-rating-likes {{ computedLikesCount }}
      | &nbsp;/&nbsp;
      span.comments__item-rating-dislikes {{ computedDislikesCount }}

  .comments__item-image-placeholder(v-if="showImgPlaceholder") {{ $options.text.placeholderImageText }}

  .comments__item-content(data-jest="content", v-html="content")

  .comments__item-media(v-if="showMedia")
    img.comments__item-image(
      :src="commentImage",
      v-if="files.length > 0",
      @click.stop.prevent="showFullImage",
      :alt="$options.text.image"
    )
    .comments__item-video-thumb(
      v-if="videos.length > 0",
      @click.stop.prevent="showEmbed"
    )
      img(:src="videos[0].thumb", :alt="$options.text.embed")
      .comments__item-play-icon
        include ./images/play-icon.svg

    .comments__item-embed(
      v-if="mode === 'showEmbed'",
      @click.prevent.stop="hideEmbed"
    )
      iframe(
        @click.stop.prevent="NOOP",
        width="900",
        height="506",
        :src="videos[0].embed_url",
        frameborder="0",
        webkitallowfullscreen,
        mozallowfullscreen,
        allowfullscreen
      )

    .comments__item-image-full(
      v-if="mode === 'showImage'",
      @click.stop.prevent="hideFullImage"
    )
      img.comments__item-image-full-inside(
        :src="commentImage",
        :alt="$options.text.image"
      )

  a.comments__item-article-title(:href="getLink(false)") {{ title }}

  a.comments__item-link(
    data-jest="link"
    v-if="postSlug"
    @click="$emit('clicked-on-item')"
    :href="getLink(true)"
    aria-label="Перейти к комментарию"
  )
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import type { FetchTypeBySlugResponse } from '~/api/health/fetchTypeBySlug';

  import { TALKER_API_URL } from '~/constants/config';
  import { NOOP } from '~/utils';
  import getSlugFromUrl from '~/utils/getSlugFromUrl';
  import { getCommentDateText } from '@devhacker/shared/utils/date';

  const DEFAULT_AVATAR =
    'data:image/jpeg;base64,R0lGODlhMgAyAMQAAPP1+dzh7eXp8tHY58jR4s3V5O7x9vn6/Oru9NTa6Pz9/uns8/b4+vX2+v7+//b3+trg7MvT5MfQ4vj5+9fd6t7k7uPn8MbO4czT5PHz+NXc6dbc6dLZ6MbP4f///8TN4CH5BAAAAAAALAAAAAAyADIAAAX/YCeOZGmeaKqubJt+cCzPdG3feK7vfF8PFcMk4ivaCIGMZ+kJGJ8xDYBJHUCNmAd164FcexWuGPHVLcToR/kmQLvV61nETffGY206unGPKfViDn0wDIBogx8HhmIdg4qLWxeDE5BbjX1alUyIU5pLiH+aCohnnhODBY+eG32lnh5wcRCvSwt9A7RNfRK5HIO5RH2znraIrwSIH6GLgskBmgbJHxeaCdIfBpCj1x+QVtzLdNwwnYDjiYuX15AY3LyLGtcEmvHJGpoC0nmQfMkKnsHidLCQC0FAKBgQ5KKSwZeRDeUWUjngZIcEC/8k0lnQzsaAbBoXNaAwgwKlkKIqA3wIAQA7';

  export default defineNuxtComponent({
    name: 'CommentCard',

    text: {
      avatar: 'Аватар автора комментария',
      placeholderImageText: 'Изображение',
      image: 'Картинка, прикрепленная к комментарию',
      embed: 'Превью эмбеда',
    },

    props: {
      avatar: {
        type: Object as PropType<CommentItem['avatar']>,
        required: true,
      },

      author: {
        type: String as PropType<string>,
        required: true,
      },

      timestamp: {
        type: String as PropType<string>,
        required: true,
      },

      likesCount: {
        type: Number as PropType<number | undefined>,
        default: 0,
      },

      dislikesCount: {
        type: Number as PropType<number>,
        required: true,
      },

      content: {
        type: String as PropType<string>,
        required: true,
      },

      title: {
        type: String,
        required: true,
      },

      postSlug: {
        type: String as PropType<string>,
        default: null,
      },

      postType: {
        type: String as PropType<string>,
        default: null,
      },

      commentId: {
        type: Number as PropType<number>,
        required: true,
      },

      showMedia: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      files: {
        type: Array as PropType<Array<any>>,
        default: () => [],
      },

      videos: {
        type: Array as PropType<Array<any>>,
        default: () => [],
      },
    },

    data(): { mode: 'normal' | 'showEmbed' | 'showImage' } {
      return {
        mode: 'normal' as 'normal' | 'showEmbed' | 'showImage',
      };
    },

    setup(props) {
      const { data: differentTypePageComment } = useAsyncData<FetchTypeBySlugResponse>(
        useId(),
        async () => {
          if (props.postType === 'health') {
            try {
              const app = useNuxtApp();
              const resp = await app.$healthApi.fetchTypeBySlug(props.postSlug);
              return resp;
            } catch (e) {
              console.error('Error in CommentCard:', e);
            }
          }
          return null;
        },
      );

      const commentTimeAgo = useState(`COMMENT_TIME_AGO_${props.commentId}`, () =>
        getCommentDateText(props.timestamp),
      );

      onMounted(() => {
        commentTimeAgo.value = getCommentDateText(props.timestamp);
      });

      return {
        differentTypePageComment,
        commentTimeAgo,
      };
    },

    computed: {
      showImgPlaceholder(): boolean {
        // В виджете отключен показ медиа, потому выводим заглушку
        if (this.showMedia) {
          return false;
        }

        return !!this.files.length && !this.content;
      },

      computedLikesCount(): string {
        return this.likesCount ? `+${this.likesCount}` : '0';
      },
      computedDislikesCount(): string {
        return this.dislikesCount ? `-${this.dislikesCount}` : '0';
      },
      computedAvatar(): string {
        const avatarUrl = this.avatar && this.avatar.url ? this.avatar.url : null;
        return avatarUrl && avatarUrl !== 'unknown' ? avatarUrl : DEFAULT_AVATAR;
      },
      commentImage(): string {
        if (this.files.length) {
          const { path } = this.files[0];
          const newPath = path.replace('public/', 'storage/');
          return `${TALKER_API_URL}/${newPath}`;
        }

        return '';
      },
    },

    methods: {
      NOOP,
      showFullImage(): void {
        this.mode = 'showImage';
      },
      hideFullImage(): void {
        this.mode = 'normal';
      },
      showEmbed(): void {
        this.mode = 'showEmbed';
      },
      hideEmbed(): void {
        this.mode = 'normal';
      },
      getLink(isCommentLink: boolean): string {
        const { postSlug, postType, commentId } = this;
        const slug =
          this.differentTypePageComment && getSlugFromUrl(this.differentTypePageComment.url);

        switch (postType) {
          case 'recipe':
            return isCommentLink
              ? `/recipe/${postSlug}/#comment-${commentId}`
              : `/recipe/${postSlug}/`;
          case 'health':
            return isCommentLink ? `${slug}#comment-${commentId}` : `${slug}`;
          default:
            return isCommentLink ? `/${postSlug}/#comment-${commentId}` : `/${postSlug}/`;
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  $borderColor: #e7e7e7;
  $textColor: #000000;
  $likeColor: #1c8920;
  $dislikeColor: #ed0000;
  $gray-text: #5c5c5c;

  .comments {
    &__item {
      position: relative;
      color: inherit;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-height: 113px;
      text-decoration: none;
      padding: 16px 16px;
      cursor: pointer;

      &:not(:last-child):before {
        position: absolute;
        bottom: 0;
        left: 16px;
        width: calc(100% - 32px);
        height: 1px;
        background-color: $borderColor;
        content: '';
      }

      @include tablet() {
        min-height: 0;
      }

      @include hover {
        &:after {
          position: absolute;
          left: -1px;
          top: -1px;

          box-sizing: border-box;
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          content: '';
          border: 2px solid var(--main-color);
        }
      }
    }

    &__item-link {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;

      width: 100%;
      height: 100%;
    }

    &__item-top {
      width: 100%;
      display: flex;
      margin-bottom: 8px;
    }

    &__item-avatar {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin-right: 8px;

      font-size: 0;
    }

    &__item-article-title {
      @include fontSmallText;

      text-align: left;
      -webkit-box-orient: vertical;
      white-space: normal;
      object-fit: contain;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 8px;
      margin-left: 4px;
      color: $gray-text;
      position: relative;
      text-decoration: none;
      z-index: 4;

      @include hover {
        color: #000;
      }
    }

    &__item-author {
      @include fontH4AccentNormalText;

      word-wrap: break-word;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      -o-object-fit: contain;
      object-fit: contain;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    &__item-date {
      font-family: $mainFont;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $gray-text;
      margin-bottom: 0;
    }

    &__item-content {
      @include fontNormalText(true);
      max-width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      text-align: left;
      padding: 3px 4px;
      margin: -3px 0;
      box-decoration-break: clone;
      text-decoration: none;
      max-height: 80px;

      @include tablet {
        max-height: 50px;
        -webkit-line-clamp: 2;
      }

      .other-content__right-sidebar & {
        @include fontTextBtn;
        font-weight: 400;
      }

      > * {
        display: inline;
      }
    }

    &__item-rating {
      @include fontSmallText;
      font-weight: 600;
      line-height: 16px;
      margin-left: auto;
      color: $borderColor;
      margin-bottom: 0;
      align-self: flex-start;
    }

    &__item-rating-likes {
      color: $likeColor;
    }

    &__item-rating-dislikes {
      color: $dislikeColor;
    }

    &__item-top-wrapper {
      display: inline-block;
      margin-top: -4px;
      max-width: calc(100% - 80px);
    }

    &__item-media {
      margin: 8px 0 12px;

      &:empty {
        display: none;
      }
    }

    &__item-image-placeholder {
      @include normalText;

      width: 120px;
      padding: 6px 8px;
      border-radius: 2px;
      background: #fbf8f8;
    }

    &__item-image {
      width: auto;
      max-height: 224px;
      max-width: 100%;
      cursor: zoom-in;
      margin: 0 8px 0 0;
      z-index: 3;
      position: relative;
    }

    &__item-image + &__item-video-thumb {
      margin: 4px 0 0 0;
    }

    &__item-video-thumb {
      width: auto;
      position: relative;
      z-index: 3;
      display: inline-block;
      cursor: zoom-in;

      img {
        max-width: 100%;
        width: auto;
        max-height: 224px;
      }
    }

    &__item-play-icon {
      border-radius: 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 32px;
      height: 32px;
    }

    &__item-embed {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.75);
      z-index: 1900;
      cursor: zoom-out;
    }

    &__item-image-full {
      position: fixed;
      z-index: 8050;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.75);
      cursor: default;
    }

    &__item-image-full-inside {
      cursor: zoom-out;
      max-height: 80%;
      width: auto;
      max-width: 80%;
      display: block;
    }
  }
</style>
